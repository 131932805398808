import React, { useState, useEffect } from "react";

import facebook from "../Images/facebook-f.webp";
import instagram from "../Images/instagram.webp";
import linkedin from "../Images/linkedin.webp";
import twitter from "../Images/whiteTwitter.webp";
import abcIT from "../Images/abcIT.webp";
import abcMartech from "../Images/talkcmo1.png";
import abcDev from "../Images/TalkDev.webp";
import abcFin from "../Images/TalkFintech.webp";
import abcEt from "../Images/enterpriseLogo.webp";
import "../Styles/Modal.css";
import { Container, Form, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import TalkMartech from "../Images/abcMartech.webp";
import "../Styles/Content.css";
import newss from "../Images/martech.gif";
import newlogo from "../Images/martech-logo.webp"
import { API_ROOT } from "../apiconfig";
// import LazyLoad from "react-lazyload";

import "../Styles/Footer.css";

export function FooterComp() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [policyText, setPolicyText] = useState(
    "*By clicking on the Submit button, you are agreeing with the Privacy Policy with Talk Martech."
  );

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setIsValidEmail(isValid);
    return isValid;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail();
  };

  const handleEmailBlur = () => {
    validateEmail(email);
  };



 
  const resetForm = () => {
    setEmail("");
    setIsValidEmail(true);
    setPolicyText(
      "*By clicking on the Submit button, you are agreeing with the Privacy Policy with Talk Martech."
    );
  };
 
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
 
    if (validateEmail()) {
      try {
        const response = await fetch(`${API_ROOT}/api/subscribe/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });
 
        if (response.ok) {
         
          console.log("Subscription successful");
          resetForm(); 
          setPolicyText("Thank you for subscribing ✅");
 
     
          setTimeout(() => {
            resetForm();
          }, 5000);
        } else {
      
          console.error("Subscription failed");
 

          if (response.status === 404) {
           
            setPolicyText("This email is already subscribed ❌");
          }
        }
      } catch (error) {
        console.error("Error sending subscription request:", error);
      }
    } else {
      console.log("Invalid email");
    }
  };
 
  const handleChange = (e) => {
    e.preventDefault();
  };

  const [className, setClassName] = useState('');

  useEffect(() => {
    const sections = 5; 
    let currentSection = 1;

    const setSectionClassName = () => {
      const removeTimer = setTimeout(() => {
        setClassName('');
      }, 0);

      const setTimer = setTimeout(() => {
        setClassName('loaded');
        currentSection++;

        if (currentSection <= sections) {
          setSectionClassName();
        } else {
          
          window.removeEventListener('scroll', handleScroll);
        }
      }, currentSection * 150);

      return () => {
        clearTimeout(removeTimer);
        clearTimeout(setTimer);
      };
    };

    const handleScroll = () => {
     
      if (window.scrollY > 100) { 
        setSectionClassName();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 


  return (
    <>
   
    <footer className="footerPadding text-black">
      <div className="container container-max">
        <div className="row text-black mt-2">
          <div className="col-md-3 col-12 mt-3">
            <source srcSet={newlogo} type="image/webp" />
            <img
              className="FootLogo"
              src={newlogo}
              // srcSet={`${newlogo} 150w, ${newlogo}@2x 300w`}
              sizes="(max-width: 150px) 150px, 300px"
              alt="TalkCMO Logo"
              loading="lazy"
              width="150"
              height="60"
            />
            <div className="textdata mt-4">
              <p className="mt-1 just-text text-black">
                A Peer Knowledge resource - By the CXO, For the CXO
              </p>
              <p className="mt-3 just-text text-black">
                Expert inputs on challenges, triumphs, and innovative solutions
                from corporate Movers and Shakers in the global Leadership space
                to add value to Business decision-making.
              </p>
              <div className="mt-3">
                <a
                  className="fw-bold hoverHead text-black mt-3 a-tag"
                  href="mailto: media@talkmartech.com"
                >
                  Media@TalkMartech.com
                </a>
              </div>
              <div className="mt-3">
                <a
                  className="fw-bold hoverHead text-black a-tag"
                  href="mailto: sales@talkmartech.com"
                >
                  Sales@TalkMartech.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12 mt-3">
            <h6 className="h5 fw-bold">About</h6>
            <a className="a-tag" href="/about">
              <p className=" text-black mt-3 borderB hoverHead">About Us</p>
            </a>

            <a className="a-tag" href="/contact-us">
              <p className=" text-black mt-3 borderB hoverHead">Contact Us</p>
            </a>

            <div className=" text-black mt-3 borderB">
              <p className="text-black subclickbtn hoverHead" onClick={handleShow}>
                Subscribe
              </p>
              </div>

              <div className="subscribePopUp">
              <Modal show={show} onHide={handleClose} className="subscribePopUp">
        <Modal.Header closeButton style={{ backgroundColor: "#f7a01e" }}>
          <img src={TalkMartech} alt="TalkCMO Banner" className="subslogo" />
        </Modal.Header>
        <Container className="popupBack" style={{height:"auto"}}>
          <Row>
            <Col md={5} className="mb-3 laptopmImgback">
              <img
                className="DesktopResponsive mt-3"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                src="https://enterprisetalk.com/static/media/silverlaptop.f1f016cdf7ed12c1cfe2.png"
                alt="Silver Laptop"
              />
            </Col>
            <Col md={7}>
              <div style={{ textAlign: "center" }}>
                <img
                  className="mt-3"
                  style={{
                    width: "60%",
                    borderRadius: "10px"
                  }}
                  src={newss}
                  alt="Newsletter Banner"
                />
              </div>

              <Form className="px-3 mb-3" controlId="emailInput" onSubmit={handleSubmit}>
  <Form.Group
    className="px-3 mb-3"
    controlId="exampleForm.ControlInput1"
  >
    <Form.Control
      type="email"
      placeholder="Email ID"
      onBlur={handleEmailBlur}
      autoComplete="off"
      aria-label="email"
      data-testid="TextInput"
      className={`mt-3 py-3 ${
        isValidEmail ? "" : "is-invalid"
      }`}
      value={email}
      onChange={handleEmailChange}
      onCut={handleChange}
      onCopy={handleChange}
      onPaste={handleChange}
    />
    <div
      className="mt-3 px-2 fw-bold"
      style={{ fontSize: "14px" }}
    >
      {policyText}
    </div>

    {!isValidEmail && (
      <div className="invalid-feedback">
        Please enter a valid email address.
      </div>
    )}
  </Form.Group>
  <Modal.Footer className="start">
    <button className="SubBtn" type="submit">
      Submit
    </button>
  </Modal.Footer>
</Form>
            </Col>
          </Row>
        </Container>
      </Modal>
              </div>
            
              <a href="/sitemap" className="text-decoration-none">
                <p className=" text-black mt-3 borderB hoverHead ">Sitemap</p>
              </a>
          </div>
          <div className="col-md-3 col-12 mt-3">
            <h6 className="h5 fw-bold">Policies</h6>

            <a className="a-tag" href="/privacy-policy">
              <p className=" text-black mt-3 borderB hoverHead">Privacy Policy</p>
            </a>

            <a className="a-tag" href="/opt-out-form">
              <p className=" text-black mt-3 borderB hoverHead">
                Do Not Sell My Information
              </p>
            </a>
          </div>
          <div className="col-md-3 col-12 mt-3 followPadding">
            <h6 className="h5 fw-bold">Follow us</h6>

            <div className="d-flex mt-2">
            <div className="mainDiv">
            <div>
            <a href="https://www.facebook.com/talkmartech/">
              <div className="bg-black">
             
              <source srcSet={facebook} type="image/webp" />
              <img
                src={facebook}
                alt="facebook"
                defer
              />
           
              </div>
              </a>
            </div>
            <div>
            <a href="https://www.instagram.com/talk.martech/">
              <div className="bg-black">
             
              <source srcSet={instagram} type="image/webp" />
              <img                      
                src={instagram}
                alt="instagram"
                loading="lazy"
              />
           
              </div>
              </a>
            </div>
            <div>
            <a href="https://x.com/talk_martech">
              <div className="bg-black">
             
              <img
                src={twitter}
                alt="twitter img"
                loading="lazy"
                className="ximage"
              />
           
              </div>
              </a>
            </div>
            <div>
              <a href="https://www.linkedin.com/company/talkmartech">
              <div className="bg-black">
              <source srcSet={linkedin} type="image/webp" />
              <img
                src={linkedin}
                alt="linkedin"
                loading="lazy"
              />
              </div>
            </a>
            </div>
          </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <h6 className="text-center h5 fw-bold">Other Publications</h6>
          </div>

          <div className="justify-content-center text-center otherPublic">
            <>
              <a href="https://enterprisetalk.com/">
                <img
                  className="imgPublica"
                  style={{ width: "160px" }}
                  src={abcEt}
                  srcSet={`${abcEt} 140w`}
                  sizes="140px"
                  alt="talkmar"
                  width="150"
                  height="35"
                  loading="lazy"
                />
              </a>
            </>

            <>
              <a href="https://itsecuritywire.com/">
                <img
                  className="imgPublicaEnter"
                  style={{ width: "145px", height: "43px", marginTop: "10px", objectFit:"fill" }}
                  src={abcIT}
                  srcSet={`${abcIT} 150w`}
                  sizes="150px"
                  alt="talkit"
                  width="150"
                  height="35"
                  loading="lazy"
                />
              </a>
            </>

            <>
              <a href="https://talkfintech.com/">
                <img
                  className="imgPublica"
                  style={{ width: "150px", height:"61px", objectFit:"cover", marginTop:"-4px" }}
                  src={abcFin}
                  srcSet={`${abcFin} 150w`}
                  sizes="150px"
                  alt="talkfin"
                  width="150"
                  height="35"
                  loading="lazy"
                />
              </a>
            </>
            <>
              <a href="https://talkcmo.com/">
                <img
                  className="imgPublicaCMO"
                  style={{ width: "90px", height:"35px" }}
                  src={abcMartech}
                  srcSet={`${abcMartech} 150w`}
                  sizes="150px"
                  alt="talkcmo"
                  width="150"
                  height="35"
                  loading="lazy"
                />
              </a>
            </>
            <>
              <a href="https://talkdev.com/">
                <img
                  className="imgPublicadev"
                  style={{ width: "115px", height:"57px" ,marginTop: "0px", objectFit:"cover" }}
                  src={abcDev}
                  srcSet={`${abcDev} 150w`}
                  sizes="150px"
                  alt="talkdev"
                  width="150"
                  height="35"
                  loading="lazy"
                />
              </a>
            </>
          </div>

          <div className="borderT mt-3 mb-1">
          <p className="mt-2 text-black" style={{fontSize:" 13px"}}>An Imprint of OnDot ® Media © | All Rights Reserved | <a href="/privacy-policy" className='text-decoration-none hoverHead pRed'>Privacy Policy</a></p>
          </div>
        </div>
      </div>
    </footer>
  
    </>
  );
}