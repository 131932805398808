import React, { useEffect, useState, useCallback } from "react";
import "../Styles/Podcast.css";
import { CardComp } from "../Component/CardComp";
import axios from "axios";
import { webPath } from "../apiconfig";
import { useMediaQuery } from "react-responsive";

const PodcastComp = ({ order }) => {
  const [data, setData] = useState(JSON.parse(localStorage.getItem("podcastData"))|[]);
  const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const dataCompWidth = isLargeScreen ? 500 : 200;
  const fetchData = useCallback(async () => {
    try {
      // Fetch data from the API
      const response = await axios.get(
        "https://app-node-talkmartech.ondotmail.com:3017/api/post/homepodcast"
      );
      const fetchedData = response.data;
  
      const localData = localStorage.getItem('podcastData');
  

      if (JSON.stringify(fetchedData) !== localData) {
        // If the data is different, update state and local storage
        setData(fetchedData);
        localStorage.setItem("podcastData", JSON.stringify(fetchedData));
     
      } else {
        // If the data matches, use the data from local storage
        setData(JSON.parse(localData));
    
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);
  

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(fetchData, 10000); 

   
    return () => clearInterval(intervalId);
  }, [fetchData]);

  if (!data.length) return null; // Conditional rendering to avoid accessing undefined data

  return (
    <div className="container container-max mt-5 mb-5">
      <div className="row">
        <h3 className="fw-bold borderB py-1 h4">
          {order ? "Guest Author" : "Podcast"}
        </h3>

        <div className={`col-md-7 m-auto ${order ? "order-md-2" : ""}`}>
          <a
            className="a-link text-black"
            href={`/${data[0]?.cat_slug}/${data[0]?.post_name}`}
          >
            <h5 className="h1 fw-bold hoverHead">{data[0]?.post_title}</h5>
            <p>{data[0]?.post_content}</p>
            <button className="podBtn mt-2">Hear The Podcast</button>
          </a>
        </div>

        <div className={`col-md-5 m-auto mt-3 ${order ? "order-md-1" : ""}`}>
          <CardComp
            src={`${webPath}${data[0]?.banner_img}?width=${dataCompWidth}`}
            alt={data[0]?.post_name}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(PodcastComp);
